import {NavLink} from "react-router-dom";
import * as ROUTES from "../constants/routes";
import Logo from "../assets/images/logo.png";
import React, {useEffect, useState, startTransition, Suspense} from "react";
import {motion} from "framer-motion";
import axios from "axios";
import {ADDRESS} from "../constants/routes";
import {useTranslation} from 'react-i18next';
import '../i18n';

export default function Header(props) {
    const {i18n} = useTranslation();
    const {t} = useTranslation("header");
    const [uniqueProfessions, setUniqueProfessions] = useState([]);
    const [uziv, setUziv] = useState({});
    const animateNavbar = {
        hidden: {
            y: "-100%",
            transition: {
                duration: 1,
                ease: "easeInOut",
            },
        },
        show: {
            y: 0,
            transition: {
                duration: 0.9,
                ease: "easeInOut",
            },
        },
    };
    useEffect(() => {
        console.log("WE ARE HERE")
        console.log(window.location.hostname)
        console.log(window.location.hostname.includes("kechains.com"))
        console.log("WE ARE HERE")
        if (window.location.hostname.includes("kechains.com")) {
            window.location.href = "https://www.projectpsk.cz?lang=en";
        }

    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        // eslint-disable-next-line no-restricted-globals
        const searchParams = new URLSearchParams(location.search);
        const langParam = searchParams.get("lang");

        console.log("XXX")
        console.log(langParam)
        console.log("XXX")

        if (langParam === "en") {
            await i18n.changeLanguage("en-EN");
        }

        await Promise.race([
            i18n.changeLanguage("en-EN"),
            new Promise((_, reject) => setTimeout(() => reject(new Error("Timeout exceeded")), 3000)) // 5000 ms timeout
        ]);

        // eslint-disable-next-line no-restricted-globals
    }, [i18n]);

    useEffect(() => {
        startTransition(() => {
            // Check if the data is already cached
            const cachedProfessions = sessionStorage.getItem("uniqueProfessions");
            if (cachedProfessions) {
                setUniqueProfessions(JSON.parse(cachedProfessions));
            } else {
                axios.get(`${ADDRESS}/osobnosti/profession-unique`)
                    .then(response => {
                        setUniqueProfessions(response.data);
                        // Cache the data
                        sessionStorage.setItem("uniqueProfessions", JSON.stringify(response.data));
                    })
                    .catch(error => {
                        console.error("Error fetching unique professions: ", error);
                    });
            }
        });
    }, []);

    const navbarLinks = [
        {id: 2, name: t("eshop"), to: ROUTES.ESHOP},
        {id: 2, name: t("profession"), to: ""},
        {id: 3, name: "Faq", to: ROUTES.FAQ},
        {id: 4, name: t("interested"), to: ROUTES.ZAJEMCI},
        {id: 6, name: t("providers"), to: ROUTES.POSKYTOVATELÉ},
        {id: 7, name: t("intermediaries"), to: ROUTES.ZPROSTREDKOVATELE},
        {id: 8, name: t("gifting"), to: ROUTES.VYPIS_OBDAROVANI},
        {id: 9, name: t("cart"), to: ROUTES.KOSIK},
        {id: 10, name: t("gift"), to: ROUTES.SLEVOVE_KODY},
    ];

    const [isActive, setIsActive] = useState(false);
    const [linkTo, setLinkTo] = useState(ROUTES.PROFIL);

    const handleActive = () => {
        setIsActive(!isActive);
    };

    useEffect(() => {
        startTransition(() => {
            setUziv(props.user);

            if (localStorage.getItem("user")) {
                axios.get(ADDRESS + `/fetch-row/profil/${JSON.parse(localStorage.getItem("user")).email}`).then(
                    res => {
                        res.data.user_email ? setLinkTo(ROUTES.PROFIL) : setLinkTo(ROUTES.FORM_OBDAROVANI);
                    }
                );
                props.loading(false);
            }
        });
    }, [props.update, props.user]);

    const handleLanguageChange = (e) => {
        const selectedValue = e.target.value;
        i18n.changeLanguage(selectedValue);
    };

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className="h-24 bg-white auto-padding fixed w-screen flex items-center justify-between shadow-xl z-50">
                <NavLink to={ROUTES.HOME} className="">
                    <img src={Logo} alt="logo" className="lg:h-14 h-12"/>
                </NavLink>
                <div
                    className={isActive ? "navbar-button isActive" : "navbar-button"}
                    onClick={handleActive}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="ul-header-list ">
                    <ul className="flex text-black items-center">
                        <NavLink
                            to={ROUTES.HOME}
                            activeClassName="active-header"
                            className="header-li mini-text"
                            exact
                        >
                            <li>{t("home")}</li>
                        </NavLink>
                        {navbarLinks.map((item) => {
                            const {name, id, to} = item;
                            return name === 'Profese' ? (
                                <div className="dropdown" key={id}>
                                    <p className="header-li mini-text" style={{marginTop: "0px"}}>
                                        {name}
                                    </p>
                                    <div className="dropdown-content">
                                        {uniqueProfessions.map((profession, index) => (
                                            <NavLink key={index} to={`/eshop?profession=` + profession.profession}
                                                     className="dropdown-item">
                                                {profession.profession}
                                            </NavLink>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <NavLink
                                    to={to}
                                    activeClassName="active-header"
                                    className="header-li mini-text"
                                    key={id}
                                >
                                    {name}
                                </NavLink>
                            );
                        })}

                        {(uziv.type === "admin") ?
                            (<NavLink
                                to={ROUTES.ADMIN}
                                activeClassName="active-header"
                                className="my-auto px-4"
                            >
                                <li className="py-1.5 px-6 rounded-md bg-main-green font-bold text-white tracking-wider transitions hover:bg-dark-green">
                                    {t("admin")}
                                </li>
                            </NavLink>)
                            :
                            (uziv.type === "user") ?
                                (<NavLink
                                    to={linkTo}
                                    activeClassName="active-header"
                                    className="my-auto px-4"
                                >
                                    <li className="py-1.5 px-6 rounded-md bg-main-green font-bold text-white tracking-wider transitions hover:bg-dark-green">
                                        {t("profile")}
                                    </li>
                                </NavLink>)
                                :
                                (<NavLink
                                    to={ROUTES.PRIHLASENI}
                                    activeClassName="active-header"
                                    className="my-auto px-4"
                                >
                                    <li className="py-1.5 px-6 rounded-md bg-main-green font-bold text-white tracking-wider transitions hover:bg-dark-green  ">
                                        {t("login")}
                                    </li>
                                </NavLink>)
                        }
                        <select onChange={handleLanguageChange} className="my-auto px-4"
                                defaultValue={i18n.language}>
                            <option value="en">EN</option>
                            <option value="cs">CS</option>
                            <option value="de">DE</option>
                            <option value="es">ES</option>
                            <option value="hu">HU</option>
                            <option value="ro">RO</option>
                            <option value="ru">RU</option>
                            <option value="sk">SK</option>
                        </select>
                    </ul>
                </div>
            </div>
            <div className="h-24"></div>
            <motion.div
                className="menu-wrapper min-h-screen left-0 top-0 w-screen fixed"
                variants={animateNavbar}
                animate={isActive ? "show" : "hidden"}
                initial="hidden"
                onClick={handleActive}
            >
                <div className="h-24"></div>

                <ul className="flex flex-col text-center items-center bg-white  shadow-xl  text-black ">
                    <NavLink
                        to={ROUTES.HOME}
                        activeClassName="active-header"
                        className="header-li w-full mt-3"
                        exact
                        onClick={() => setIsActive(!isActive)}
                    >
                        <li>{t("home")}</li>
                    </NavLink>
                    {navbarLinks.map((item) => {
                        const {name, id, to} = item;
                        return (
                            <NavLink
                                to={to}
                                activeClassName="active-header"
                                className="header-li  w-full"
                                key={id}
                                onClick={() => setIsActive(!isActive)}
                            >
                                {name}
                            </NavLink>
                        );
                    })}
                    {(uziv.type === "admin") ?
                        (<NavLink
                            to={ROUTES.ADMIN}
                            activeClassName="active-header"
                            className="pb-7 pt-2 w-40"
                        >
                            <li className="py-1 flex justify-center px-6 rounded-md bg-main-green font-bold text-white tracking-wider transitions hover:bg-dark-green">
                                {t("admin")}
                            </li>
                        </NavLink>)
                        :
                        (uziv.type === "user") ?
                            (<NavLink
                                to={linkTo}
                                activeClassName="active-header"
                                className="pb-7 pt-2 w-40"
                            >
                                <li className="py-1 px-6 rounded-md bg-main-green font-bold text-white tracking-wider transitions hover:bg-dark-green  ">
                                    {t("profile")}
                                </li>
                            </NavLink>)
                            :
                            (<NavLink
                                to={ROUTES.PRIHLASENI}
                                activeClassName="active-header"
                                className="pb-7 pt-2 w-40"
                            >
                                <li className="py-1 px-6 rounded-md bg-main-green font-bold text-white tracking-wider transitions hover:bg-dark-green  ">
                                    {t("login")}
                                </li>
                            </NavLink>)
                    }
                    <select onChange={handleLanguageChange} className="my-auto px-4 mb-8"
                            defaultValue={i18n.language}>
                        <option value="en">EN</option>
                        <option value="cs">CS</option>
                        <option value="de">DE</option>
                        <option value="es">ES</option>
                        <option value="hu">HU</option>
                        <option value="ro">RO</option>
                        <option value="ru">RU</option>
                        <option value="sk">SK</option>
                    </select>

                </ul>
            </motion.div>
        </Suspense>
    );
}
