import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'cs',
        debug: false,
        logger: {
            log: function () {
            },     // Disable normal logs
            warn: function () {
            },    // Disable warnings
            error: function () {
            }    // Disable errors
        },
        interpolation: {
            escapeValue: false, // React already does escaping
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        ns: ['home', "eshop", "faq", "fomrular-obdarovani", "about", "karta", "kody",
            "kosik", "nove-heslo", "obdarovani-detail", "obdarovani", "osobnost",
            "poskytovatele", "prihlaseni", "registrace", "unlockWeb", "vyhledavani",
            "zajemci", "zapomenute-heslo", "zprostredkovatele", "profile-edit", "profile",
            "fileUploader", "footer", "header", "kosikFirst", "kosikFourth", "kosikSecond",
            "kosikThird", "loader", "notFound", "gdpr"
        ], // Namespaces for different sections
        defaultNS: 'home',
    });

export default i18n;
