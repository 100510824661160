import { useTranslation } from 'react-i18next';

export default function Loader (){
    const { t } = useTranslation("loader");
    return(
        <div className={"loader-component"}>
            <div className={"loader"}></div>
            <p>{t("loading")}</p>
        </div>
    )
}

export function SendMessLoader(){
    const { t } = useTranslation("loader");
    return(
        <div className={"loader-mess-component"}>
            <div className={"loader"}></div>
            <p>{t("sending")}</p>
        </div>
    )
}