import { Link } from "react-router-dom";
//import {ADDRESS} from "../constants/routes";
import * as ROUTES from "../constants/routes";
import gsap from "gsap";
import { useRef, useState, useEffect } from "react";
import {SendMessLoader} from "./loader";
import { useTranslation } from 'react-i18next';

import tiktok from "../assets/images/icons/tiktok.svg"
import facebook from "../assets/images/icons/facebook.svg"
import instagram from "../assets/images/icons/instagram.svg"
import youtube from "../assets/images/icons/youtube.svg"
import gmail from "../assets/images/icons/gmail.svg"

export default function Footer(props) {
  const { t } = useTranslation("footer");

  let alertSuccess = useRef(null);
  let alertEmptyEmail = useRef(null);
  let alertFailed = useRef(null);
  const [logged, setLogged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [commentText, setCommentText] = useState("");
  const [errors, setErrors] = useState();

  const [disable, setDisable] = useState(false);

  const sendMessage = async () => {
    setLoading(true)
    let data = {
      email,
      name,
      subject,
      message:commentText
    };
    let result = await fetch(`${ROUTES.ADDRESS}/save/messages`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': "application/json",
        'Accept': "application/json",
      },
    });
    let resultJson = await result.json();
    if (result.status === 422) {
      setLoading(false)
      setErrors(resultJson.errors);
      console.log(errors);
    } else {      setLoading(false)

      // setName("")
      // setSubject("");
      // setEmail("");
      // setCommentText("");
    }
  };

  const animateSuccess = () => {
    gsap.to(alertSuccess, {
      y: 555,
      yoyo: true,
      repeat: 1,
      repeatDelay: 1.1,
      autoAlpha: 1,
    });
  };
  const animateEmptyEmail = () => {
    gsap.to(alertEmptyEmail, {
      y: 555,
      yoyo: true,
      repeat: 1,
      repeatDelay: 1.1,
      autoAlpha: 1,
    });
  };
  const animateFailed = () => {
    gsap.to(alertFailed, {
      y: 555,
      yoyo: true,
      repeat: 1,
      repeatDelay: 1.1,
      autoAlpha: 1,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setDisable(true);

    setTimeout(() => {
      setDisable(false);
    }, 2100);
    let temp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    let valid = false
    if (temp.test(email)){
        valid = true
    }
    if ( email === "" || subject === "" || commentText === "" || name === ""){
      animateEmptyEmail()
    }else if(!valid){
      animateFailed()
    }else{
      sendMessage()
      animateSuccess()
    }


  };
  useEffect(()=>{
    if (props.uziv.id || props.uziv.logged){
      setLogged(true)
    }else{
      setLogged(false)
    }
  }, [props.uziv, logged])

  return (
    <>
      <div
        className="alert-success alert-footer bg-green-500 alert-style"
        ref={(el) => (alertSuccess = el)}
      >
        {t('alertSuccess')}
      </div>
      <div
        className="alert-empty-email alert-footer bg-red-500 alert-style"
        ref={(el) => (alertEmptyEmail = el)}
      >
        {t('alertEmptyEmail')}
      </div>
      <div
        className="alert-empty-email alert-footer bg-red-500 alert-style"
        ref={(el) => (alertFailed = el)}
      >
        {t('alertFailed')}
      </div>

      <div className="gradient-bg">
        <div className="footer-container auto-padding grid xl:grid-cols-12 gap-y-4 md:grid-cols-6 ">
          <div className="dokumenty-wrapper  relative overflow-x-visible xl:col-span-3 md:col-span-3 tracking-wider xl:text-left text-center xl:py-16 lg:py-8 md:py-8   pt-5  ">
            <h1 className="nadpis-footer malej-nadpis -mb-2 lg:tracking-wider tracking-wide">
              {t('documents')}
            </h1>
            <ul className="malej-text odkazy-footer  ">
              <li>
                <a href={ROUTES.VOP}>{t('vop')}</a>
              </li>
              <li>
                <a href={ROUTES.GDPR}>{t('gdpr')}</a>
              </li>
              <li>
                <a href="#">Cookies</a>
              </li>
            </ul>

          </div>
          <div className="kontakt-wrapper  xl:py-16 lg:py-8 md:py-8 py-3 xl:col-span-3 md:col-span-3 tracking-wider text-center flex justify-start h-full xl:items-start items-center flex-col xl:pl-4 ">
            <div>
              <h1 className="nadpis-footer -mb-2 malej-nadpis lg:tracking-wider tracking-wide">
                {t('socials')}
              </h1>
              <ul className="malej-text flex justify-center gap-2">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100084281923460"
                    target="_blank"
                    rel="noreferrer"
                    className="transitions text-dark-grey hover:text-white"
                  >
                    <img src={facebook}
                      className="w-6 h-6" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/pskpskproject/"
                    target="_blank"
                    rel="noreferrer"
                    className="transitions text-dark-grey hover:text-white"
                  >
                    <img src={instagram}
                      className="w-6 h-6" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.tiktok.com/@project.psk"
                    target="_blank"
                    rel="noreferrer"
                    className="transitions text-dark-grey hover:text-white"
                  >
                    <img src={tiktok}
                      className="w-6 h-6" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCByz-0k-ywfzsFAzJoBpoYg"
                    target="_blank"
                    rel="noreferrer"
                    className="transitions text-dark-grey hover:text-white"
                  >
                    <img src={youtube}
                      className="w-6 h-6" />
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:pskpskproject@gmail.com"
                    className="transitions text-dark-grey hover:text-white"
                  >
                    <img src={gmail}
                      className="w-6 h-6" />
                  </a>
                </li>
              </ul>
              {!logged && <Link to={ROUTES.PRIHLASENI}>
                <button className="btn-footer  bg-transparent border-2 hover:bg-white hover:text-main-green border-white transitions rounded-xl sm:w-52 w-44 sm:my-2 my-1.5 sm:px-2 px-1.5 text-white">
                  {t('login')}
                </button>
              </Link> }
            </div>
          </div>
          <div
              className="formular-wrapper xl:col-span-6 flex justify-center  md:col-span-full xl:py-16 xl:pb-12 md:pb-8 pb-8">
            <form action="#" className="footer-form xl:w-full md:w-4/5 w-full">
          {loading ? <SendMessLoader/> :
              <div className="grid md:grid-cols-3 input-form grid-cols-1 form-wrapper gap-x-2 ">
                <input
                    type="text"
                    placeholder={t('name')}
                    className="md:col-span-1 col-span-3"
                    onChange={(e) => setName(e.target.value)}
                    value={name}

                />
                <input
                    type="text"
                    placeholder="Email"
                    className="md:col-span-1 col-span-3"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}

                />
                <input
                    type="text"
                    placeholder={t('subject')}
                    className="md:col-span-1 col-span-3 "
                    onChange={(e) => setSubject(e.target.value)}
                    value={subject}
                />
                <textarea
                    placeholder={t('message')}
                    className="col-span-3 h-32"
                    style={{resize: "none"}}
                    onChange={(e) => setCommentText(e.target.value)}
                    value={commentText}
                />
                <button
                    onClick={submitHandler}
                    disabled={disable}
                    className="btn-footer mt-1 col-span-3 bg-transparent border-2 transitions hover:bg-white hover:text-main-green border-white rounded-xl w-full py-0.5  px-2 text-white "
                >
                  {t('submit')}
                </button>
              </div>
           }
            </form>
          </div>
        </div>

      </div>
    </>
  );
}
