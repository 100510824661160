export const HOME = "/";
export const ESHOP = "/eshop";
export const ESHOP_DETAIL = "/eshop/detail/:id/:productId";

export const FAQ = "/faq";
export const ZAJEMCI = "/zajemci";
export const ABOUT = "/o-projektu";
export const SLEVOVE_KODY = "/darovani";

export const GDPR = "/gdpr";
export const VOP = "/vop";

export const REGISTRACE = "/registrace";
export const ODHLASENI = "/odhlaseni";
export const KOSIK = "/kosik";

export const POSKYTOVATELÉ = "/poskytovatele";
export const ZPROSTREDKOVATELE = "/zprostredkovatele";
export const PRIHLASENI = "/prihlaseni";
export const KARTA = "/karta";
export const VYHLEDAVANI = "/vyhledavani";
export const ZAPOMENUTE_HESLO = "/zapomenute-heslo";
export const NOVE_HESLO = "/nove-heslo/:email";
export const FORM_OBDAROVANI = "/formular-obdarovani";
export const DETAIL_OBDAROVANI = "/detail-obdarovani/:id";
export const VYPIS_OBDAROVANI = "/obdarovani";


//admin
export const ADMIN = "/admin";
export const ADMIN_IMAGES = "/admin/obrazky";
export const ADMIN_IMAGE_NEW = "/admin/obrazky/novy"
export const ADMIN_MESSAGES = "/admin/messages";
export const ADMIN_EMAILS = "/admin/emails";
export const ADMIN_PRODUCTS = "/admin/products";
export const ADMIN_PRISPEVKY = "/admin/prispevky";
export const ADMIN_OSOBNOSTI = "/admin/osobnosti";
export const ADMIN_OSOBNOSTI_FORM = "/admin/vytvorit-osobnost";
export const ADMIN_OSOBNOSTI_EDIT= "/admin/osobnost-uprava/:id";

export const ADMIN_ORDERS = "/admin/orders";
export const ADMIN_PRODUKT = "/admin/vytvorit_produkt";
export const ADMIN_UPRAVIT_PRODUKT= "/admin/upravit-produkt/:id";

export const OSOBNOST_DETAIL= "/osobnost/:name";
export const OSOBNOST_DETAIL_PATH= "/osobnost/";


export const PROFIL = "/profil";
export const PROFIL_EDIT = "/profil/edit/:email";
export const PROFIL_DELETE = "/profil/delete/:email";


export const KARTA_DETAIL = "/karta-detail";

export const ADDRESS = process.env.NODE_ENV === 'production' ? "https://api.projectpsk.cz" : "http://127.0.0.1:8000";
