import React, {useEffect, useState, useReducer} from "react";
import Header from "./components/header";
import * as ROUTES from "./constants/routes";
import {lazy, Suspense} from "react";
import {BrowserRouter as Router, Switch, Route, useHistory, Redirect} from "react-router-dom";
import "./App.scss";
import Footer from "./components/footer";
import 'swiper/swiper-bundle.min.css';
// @ts-ignore
// eslint-disable-next-line
const AdminMessages = lazy(() => import('./pages/admin/admin-messages.tsx'));
const AdminPrispevky = lazy(() => import("./pages/admin/admin-prispevky"));
const AdminEmails = lazy(() => import("./pages/admin/admin-emails.tsx"));
const AdminDashboard = lazy(() => import("./pages/admin/admin-dashboard.tsx"));
const AdminOrders = lazy(() => import("./pages/admin/admin-objednavky.tsx"));
const AdminProducts = lazy(() => import("./pages/admin/admin-produkty.tsx"));
const AdminProduct = lazy(() => import("./pages/admin/admin-produkt.tsx"));
const AdminOsobnosti = lazy(() => import("./pages/admin/admin-odobnosti.tsx"));
const AdminImages = lazy(() => import("./pages/admin/admin-images"));
const AdminNewImage = lazy(() => import("./pages/admin/admin-image-new"));
const OsobnostiForm = lazy(() => import("./pages/admin/formular-osobnosti"));
const OsobnostiEdit = lazy(() => import("./pages/admin/edit/formular-osobnosti-edit"));
const AdminProductUprava = lazy(() => import("./pages/admin/edit/admin-produkt-uprava.tsx"));
const Kosik = lazy(() => import("./pages/kosik"));
const Home = lazy(() => import("./pages/home"));
const About = lazy(() => import("./pages/about"));
const Sleva = lazy(() => import("./pages/kody"));
const Eshop = lazy(() => import("./pages/eshop"));
const Faq = lazy(() => import("./pages/faq"));
const Zajemci = lazy(() => import("./pages/zajemci"));
const Registrace = lazy(() => import("./pages/registrace"));
const Poskytovatele = lazy(() => import("./pages/poskytovatele"));
const Zprostredkovatele = lazy(() => import("./pages/zprostredkovatele"));
const Prihlaseni = lazy(() => import("./pages/prihlaseni"));
const Vyhledavani = lazy(() => import("./pages/vyhledavani"));
const Karta = lazy(() => import("./pages/karta"));
const ZapomenuteHeslo = lazy(() => import("./pages/zapomenute-heslo"));
const NoveHeslo = lazy(() => import("./pages/nove-heslo"));
const FormularObdarovani = lazy(() => import("./pages/formular-obdarovani"));
const Obdarovani = lazy(() => import("./pages/obdarovani"));
const DetailObdarovani = lazy(() => import("./pages/obdarovani-detail"));
const Profil = lazy(() => import("./pages/user/profil"));
const ProfilDelete = lazy(() => import("./pages/user/profil-delete"));
const ProfilEditace = lazy(() => import("./pages/user/profil-edit"));
const NotFound = lazy(() => import("./components/not-found"));
const Loader = lazy(() => import("./components/loader"));
const Osobnost = lazy(() => import("./pages/osobnost"));
const UnlockWebsite = lazy(() => import("./pages/unlockWeb"));
const LogOut = lazy(() => import("./pages/user/logout"));
const KartaDetail = lazy(() => import("./pages/karta-detail"));
const GDPR = lazy(() => import("./pages/gdpr"));
const VOP = lazy(() => import("./pages/vop"));

export default function App() {
  const [security, setSecurity] = useState(getCookie('UNLOCKED') === 'yes');
  const [errors, setErrors] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {})
  const [, forceUpdate] = useReducer(x => x + 1, 0);


  const updateThis = () => {
    forceUpdate()
    setCount(count + 1)
  }

  const signIn = async () => {
    let result = await fetch(`${ROUTES.ADDRESS}/user-profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Bearer" + localStorage.getItem("token")
      },
    });
    let resultJson = await result.json();
    switch (result.status) {
      case 422:
        setErrors(resultJson.errors);
        break;
      case 200:
        setErrors(resultJson);
        setUser(resultJson)
        localStorage.setItem("user", JSON.stringify(resultJson))
        forceUpdate()
        break;
    }
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        console.log(c.substring(name.length, c.length))
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const setIt = (bool) => {
    setSecurity(bool)
  }


  useEffect(() => {
    if (localStorage.getItem("user") == null && localStorage.getItem("token") !== null) {
      setLoading(true)
      signIn()
    }

  }, [user]);

  useEffect(() => {
    sessionStorage.removeItem("fourth");
  }, [])


  return (
      <Router>
        <Suspense fallback={<div className="h-screen bg-white"></div>}>
        {security && <Header user={user} loading={setLoading} update={count}/>}

          <div className="nejvetsi-container min-h-screen">

            {loading ?
                <Loader/>
                :
                <Switch>
                  <Route path={ROUTES.HOME} component={() => (security ?
                      <Home/> :
                      <Redirect to="/unlock_website"/>)}
                         exact/>
                  <Route path="/unlock_website" component={() => <UnlockWebsite setIt={setIt}/>}/>
                  {!security ? <Redirect to="/unlock_website"/> : null}
                  <Route path={ROUTES.ESHOP} component={Eshop} exact/>
                  <Route path={ROUTES.GDPR} component={GDPR} exact/>
                  <Route path={ROUTES.VOP} component={VOP} exact/>
                  <Route path={ROUTES.ESHOP_DETAIL} component={Karta} exact/>
                  <Route path={ROUTES.ABOUT} component={About} exact/>
                  <Route path={ROUTES.FAQ} component={Faq} exact/>
                  <Route path={ROUTES.SLEVOVE_KODY} component={Sleva} exact/>
                  <Route path={ROUTES.ZAJEMCI} component={Zajemci} exact/>
                  <Route path={ROUTES.REGISTRACE} component={Registrace} exact/>
                  <Route path={ROUTES.POSKYTOVATELÉ} component={Poskytovatele} exact/>
                  <Route path={ROUTES.PRIHLASENI} component={() => <Prihlaseni user={setUser}/>} exact/>
                  <Route path={ROUTES.VYHLEDAVANI} component={Vyhledavani} exact/>
                  <Route path={ROUTES.KARTA} component={Karta} exact/>
                  <Route path={ROUTES.ZAPOMENUTE_HESLO} component={ZapomenuteHeslo} exact/>
                  <Route path={ROUTES.NOVE_HESLO} component={NoveHeslo} exact/>
                  <Route path={ROUTES.DETAIL_OBDAROVANI} component={DetailObdarovani} exact/>
                  <Route path={ROUTES.OSOBNOST_DETAIL} component={Osobnost} exact/>
                  <Route
                      path={ROUTES.KOSIK}
                      exact
                      component={() => <Kosik/>}
                  />
                  <Route path={ROUTES.VYPIS_OBDAROVANI} component={Obdarovani} exact/>
                  <Route path={ROUTES.KARTA_DETAIL} component={KartaDetail} exact/>
                  <Route
                      path={ROUTES.ZPROSTREDKOVATELE}
                      component={Zprostredkovatele}
                      exact
                  />
                  {/*user*/}
                  <Route path={ROUTES.ODHLASENI} component={() =>
                      (user.type ?
                          <LogOut user={setUser}/> :
                          <Redirect to="/not-found"/>)
                  } exact/>
                  <Route path={ROUTES.FORM_OBDAROVANI} component={() => (user.type ?
                      <FormularObdarovani update={updateThis}/> :
                      <Redirect to="/not-found"/>)} exact/>
                  <Route path={ROUTES.PROFIL} component={() =>
                      (user.type ?
                          <Profil/> :
                          <Redirect to="/not-found"/>)
                  } exact/>
                  <Route path={ROUTES.PROFIL_EDIT} component={() =>
                      (user.type ?
                          <ProfilEditace update={updateThis}/> :
                          <Redirect to="/not-found"/>)} exact/>
                  <Route path={ROUTES.PROFIL_DELETE} component={() =>
                      (user.type ?
                          <ProfilDelete update={updateThis}/> :
                          <Redirect to="/not-found"/>)} exact/>
                  {/*Admin*/}
                  <Route
                      path={ROUTES.ADMIN_MESSAGES}
                      component={() => (user.type == "admin" ? <AdminMessages/>
                          :
                          <Redirect to="/not-found"/>)}
                      exact
                  />
                  <Route
                      path={ROUTES.ADMIN_IMAGES}
                      component={() => (user.type == "admin" ? <AdminImages/>
                          :
                          <Redirect to="/not-found"/>)}
                      exact
                  />
                  <Route
                      path={ROUTES.ADMIN_IMAGE_NEW}
                      component={() => (user.type == "admin" ? <AdminNewImage />
                          :
                          <Redirect to="/not-found"/>)}
                      exact
                  />
                  <Route
                      path={ROUTES.ADMIN_EMAILS}
                      component={() => (user.type == "admin" ? <AdminEmails/>
                          :
                          <Redirect to="/not-found"/>)}
                      exact
                  />
                  <Route
                      path={ROUTES.ADMIN_OSOBNOSTI}
                      component={() => (user.type == "admin" ? <AdminOsobnosti/>
                          :
                          <Redirect to="/not-found"/>)}
                      exact
                  />
                  <Route
                      path={ROUTES.ADMIN_PRODUCTS}
                      component={() => (user.type == "admin" ? <AdminProducts/>
                          :
                          <Redirect to="/not-found"/>)}
                      exact
                  />
                  <Route
                      path={ROUTES.ADMIN_PRISPEVKY}
                      component={() => (user.type == "admin" ? <AdminPrispevky/>
                          :
                          <Redirect to="/not-found"/>)}
                      exact
                  />
                  <Route
                      path={ROUTES.ADMIN_OSOBNOSTI_FORM}
                      component={() => (user.type == "admin" ? <OsobnostiForm/>
                          :
                          <Redirect to="/not-found"/>)}
                      exact
                  /><Route
                    path={ROUTES.ADMIN_OSOBNOSTI_EDIT}
                    component={() => (user.type == "admin" ? <OsobnostiEdit/>
                        :
                        <Redirect to="/not-found"/>)}
                    exact
                />
                  <Route
                      path={ROUTES.ADMIN_ORDERS}
                      component={() => (user.type == "admin" ? <AdminOrders/>
                          :
                          <Redirect to="/not-found"/>)}
                      exact
                  />
                  <Route
                      path={ROUTES.ADMIN_PRODUKT}
                      component={() => (user.type == "admin" ? <AdminProduct/>
                          :
                          <Redirect to="/not-found"/>)}
                      exact
                  />
                  <Route
                      path={ROUTES.ADMIN_UPRAVIT_PRODUKT}
                      component={() => (user.type == "admin" ? <AdminProductUprava/>
                          :
                          <Redirect to="/not-found"/>)}
                      exact
                  />
                  <Route
                      path={ROUTES.ADMIN}
                      component={() => (user.type == "admin" ? <AdminDashboard/>
                          :
                          <Redirect to="/not-found"/>)}
                      exact
                  />
                  <Route path="/not-found" component={NotFound}/>
                  <Redirect to="/not-found"/>
                </Switch>
            }
          </div>
          {security && <Footer uziv={user} loading={setLoading}/>}
        </Suspense>
      </Router>
  );}
